.description-text {
  font-size: 12px;
  color: #757575 !important;
  font-weight: 400;
}

.placeholder-option {
  display: none !important;
}

.custom-input:focus .placeholder-option {
  display: initial;
}

.agent-appointment-settings-form {
  height: 70vh;
  @media (max-width: 992px) {
    height: 100%;
  }
}
