.agent-view {
  padding: 16px 16px 0px 16px;
  height: 91vh;
  @media (max-width: 768px) {
    width: 100%;
  }
}
.agent-view .no-agent-view {
  height: 80%;
}
.agent-listing {
  background-color: #fff;
  border-radius: 8px;
}

.add-agent-button {
  background-color: #2f80ed;
  padding: 16px 64px;
}

.add-agent-btn-text {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}

.agent-actions-view {
  @media (max-width: 576px), (max-width: 400px) {
    flex-direction: column !important;
    gap: 8px !important;
  }
}

.agent-action-button {
  background-color: #e7e7e7;
  border-radius: 40px;
  padding: 8px 16px;
}

.agent-action-text {
  color: #090909;
  font-size: 14px;
  font-weight: 600;
}

.add-agent-view {
  background-color: #fff;
  padding: 16px;
  border-radius: 16px;
  border: 1px solid #eeeeee;
}

.number-of-agent-text {
  color: #2e2a40;
  font-size: 24px;
  font-weight: 800;
}

.total-agent-text {
  color: #757575;
  font-size: 12px;
  font-weight: 600;
  padding-left: 8px;
  padding-right: 16px;
}
.other-modal .modal-dialog {
  min-width: 687px;

  @media (max-width: 992px) {
    min-width: 400px !important;
  }
  @media (max-width: 676px) {
    min-width: 300px !important;
  }
}

.modal-dialog .modal-content {
  border-radius: 24px;
}

.delete-agent-title {
  color: #2e2a40;
  font-size: 32px;
  text-align: center;
  font-weight: 600;
}

.delete-agent-description {
  color: #4d4d4d;
  font-size: 16px;
  text-align: center;
  font-weight: 400;
}

.agent-name {
  color: #4d4d4d;
  font-size: 16px;
  text-align: center;
  font-weight: 600;
}

.delete-agent-view {
  padding: 48px;
}

.delete-agent-modal .modal-dialog {
  width: 421px !important;
}
