.dashboard-view {
  padding: 16px;
}

.dashboard-title {
  font-size: 16px;
  font-weight: 700;
  color: #2e2a40;
}

.main-class .dashboard-view .call-history-view {
  @media (max-width: 800px) {
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
  }
}

.charts-view {
  background-color: #fff;
  padding: 8px 12px;
  width: 100%;
  border-radius: 12px;
  @media (max-width: 576px) {
    flex-direction: column !important;
  }
}

.chart {
  border: 1px solid #e7e7e7 !important;
  border-radius: 12px;
  width: 100%;
  padding: 32px 16px 60px 16px;
}

.chart-title {
  font-size: 18px;
  color: #2e2a40;
  font-weight: 600;
}

.dropdown-menu {
  background-color: white !important;
}

.dropdown-toggle {
  background-color: white !important;
}

.chart-dropdown {
  border: 1px solid #e7e7e7 !important;
  border-radius: 8px !important;
}

.date-text {
  color: #2e2a40;
  font-weight: 600;
  padding-top: 1px !important;
}

.datepicker-view {
  background-color: #fff;
  border: 1px solid #e7e7e7;
  border-radius: 8px;
}
.react-datepicker__input-container button {
  padding: 16px !important;
}
.date-text {
  width: 100%;
}
