.call-info-card {
  border: 1px solid #eeeeee !important;
  border-radius: 20px;
  padding: 16px;
  background-color: white;
  gap: 30px;
  @media (max-width: 800px) {
    width: 100%;
  }
}

.call-title {
  font-size: 18px;
  color: #757575;
  font-weight: 500;
}

.number-of-calls {
  font-size: 28px;
  font-weight: 700;
  color: #2e2a40;
}
