.container {
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.text {
  font-size: 24px;
  font-weight: 600;
  color: #585757;
}
