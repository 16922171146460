.edit-profile-view {
  background-color: #fff;
  border-radius: 16px;
  padding: 16px;
  height: 82vh;
}
.edit-profile-form {
  height: 85%;
}

.user-name {
  color: #a7a7a7;
  font-size: 16px;
  font-weight: 600;
  @media (max-width: 576px), (max-width: 400px) {
    text-align: center;
  }
}

.user-info {
  border-bottom: 1px solid #e7e7e7;
}
.save-btn-view {
  margin-top: 20px;
  padding-top: 12px;
  border-top: 2px solid #f3f3f3;
}

.edit-profile-view .edit-profile-form .profile-upload-image {
  border-radius: 40px;
  height: 150px;
  width: 150px;
}

.input-file-view {
  position: relative;
  @media (max-width: 576px), (max-width: 400px) {
    margin: auto;
  }
}

.edit-profile-btn {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: #fff;
  padding: 8px;
  border-radius: 20px;
  z-index: 1;
}

.edit-profile-view .edit-profile-forms-view {
  display: flex;
  flex-direction: row;
  @media (max-width: 576px), (max-width: 400px) {
    flex-direction: column !important;
    gap: 8px !important;
  }
}
