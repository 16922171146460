.active {
  background-color: #2f80ed !important;
  border-radius: 10px;
}
.offcanvas-title {
  font-size: 16px;
  color: #757575;
  font-weight: 700;
  padding-left: 32px;
}

.active-title {
  font-size: 16px;
  color: #fff;
  font-weight: 700;
  padding-left: 32px;
}

.canvas-container {
  border-bottom: 2px solid #e9e9e9 !important;
  padding-bottom: 8px;
}

.deactivate-account-text {
  font-size: 18px;
  font-weight: 700;
  color: #eb6565;
}

.app-navigation-icons {
  position: relative;
  padding: 10px;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  border: 2px solid #eeeeee;
}

.new-updates {
  position: absolute;
  background-color: #fc372d;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  border: none !important;
  right: 25%;
  top: 30%;
}
#basic-nav-dropdown {
  background: none !important;
}
.profile-view {
  background-color: #fafafa;
  border-radius: 30px;
  @media (max-width: 992px) {
    margin-right: auto;
  }
}

.profile-image {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
.nav-item a {
  color: black;
}

.navbar-nav {
  flex: 1;
}
.dropdown-menu[data-bs-popper] {
  left: -100% !important;
}

.left-header-view {
  width: 25% !important;
  height: 100vh;
}
.search-input::placeholder {
  font-size: 16px !important;
  color: #bababa !important;
  font-weight: 500 !important;
}

.main-class-view .main-class {
  border-left: 2px solid #f3f3f3;
  background-color: #f9f9fb;
  overflow-x: scroll;
  @media (max-width: 1200px) {
    height: 100%;
  }
}
.main-class-view {
  width: 80%;
  @media (max-width: 992px) {
    width: 100%;
  }
}

.offcanvas-heading-text {
  font-size: 12px;
  font-weight: 600;
  color: #757575;
  padding-left: 16px !important;
}

.dropdown-toggle::after {
  display: none !important;
}
.navbar-expand-lg .navbar-nav .dropdown-menu {
  position: absolute;
  right: 0px !important;
  left: -70px !important;
}
.notification-text {
  font-size: 18px;
  font-weight: 600;
  color: #383838;
}

.notification-title {
  font-size: 14px;
  font-weight: 400;
  color: #383838;
}

.notification-desc {
  font-size: 14px;
  font-weight: 400;
  color: #878787;
}

.notification-card {
  border-bottom: 2px solid #e7e7e7;
  padding: 8px 0px;
}

.notification-modal {
  position: absolute;
  top: 60px !important;
  right: 20px !important;
}

.notification-modal .modal-content {
  border-radius: 12px !important;
  margin-left: auto !important;
  margin-right: 0px !important;
  max-width: 400px;
}
.notification-modal .modal-dialog {
  position: absolute;
  top: 0px !important;
  right: 20px !important;
}
