.verified-btn {
  background-color: #007aff;
  padding: 16px;
  border-radius: 12px;
}

.verification-text {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}
