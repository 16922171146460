.help-card-container {
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 387px;
  min-height: 239px;
  border-radius: 12px;
  padding: 16px;
  margin-bottom: 32px;
  @media (max-width: 992px) {
    width: 100%;
    margin-right: 24px;
  }
}

.help-description {
  color: #f5f5f5;
  font-size: 24px;
  font-weight: 400;
}
