.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.chk-ico-list-1 {
  list-style: none;
}

.chk-ico-list-1 li {
  position: relative;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #676562;
  padding-left: 26px;
  &:not(:last-child) {
    margin-bottom: 22px;
  }
  &::before {
    content: '';
    position: absolute;
    height: 18px;
    width: 18px;
    left: 0px;
    top: -2px;
    min-height: 18px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 18 18'%3E%3Cpath fill='%23028193' d='M16.5 5c0 .512-.11.998-.307 1.436a3.5 3.5 0 0 1 .257 5.97 3.5 3.5 0 0 1-4.476 3.94A3.498 3.498 0 0 1 9 18.001a3.498 3.498 0 0 1-2.973-1.653 3.5 3.5 0 0 1-4.476-3.94 3.5 3.5 0 0 1 .257-5.971 3.5 3.5 0 0 1 4.22-4.783A3.498 3.498 0 0 1 8.998 0c1.255 0 2.356.66 2.973 1.653A3.5 3.5 0 0 1 16.5 5Z'/%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='m6 10 2 2 4-6'/%3E%3C/svg%3E");
  }
}
