.image-dropper-view {
  background-color: #f3f3f3;
  padding: 16px;
  width: fit-content;
  border-radius: 8px;
}

.profile-image-desc-text {
  font-size: 16px;
  color: #a7a7a7;
  font-weight: 600;
}

.employee-time-slots {
  width: 100%;

  @media (max-width: 576px) {
    width: 95% !important;
  }
}

.react-datepicker-wrapper {
  width: 100% !important;
}
.dropdown-view {
  max-height: 200px !important;
  overflow: scroll;
}
.add-employee-spinner-view {
  height: 200px;
  width: 200px;
}

#add-employee-dropdown {
  background-color: #e7e7e7 !important;
  width: 100%;
}

.react-datepicker-wrapper .date-picker[value] {
  @media (max-width: 576px) {
    width: 75% !important;
  }

  @media (max-width: 350px) {
    width: 65% !important;
  }
}
