.help-screen-view {
  padding: 32px 0px 32px 24px;
  height: 90vh;
  flex: 1;
  gap: 32px;

  @media (max-width: 1200px) {
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
