.heading-text {
  font-size: 40px;
  font-weight: 700;
  color: #2e2a40;
}
.heading-verification-text {
  font-size: 32px;
  font-weight: 700;
  color: #2e2a40;
}
.description-text {
  font-size: 16px;
  font-weight: 400;
  color: #2e2a40 !important;
}

.auth-row {
  display: flex;
  flex: 1;
  margin: 10px !important;
}
.left-column {
  height: 100%;
  min-height: 98vh;
}
.logo-view {
  margin-right: auto;
  flex: 0.5;
  margin-top: 30px;
}
.left-column-view {
  min-width: 400px;
  max-width: 400px;
  @media (max-width: 400px) {
    min-width: auto;
    margin-top: 20px;
  }
}
.right-column {
  border-radius: 15px;
  background-color: #007aff;
  padding: 40px 40px 80px 40px !important;
  @media (max-width: 576px) {
    padding-left: 0px !important;
    padding-right: 0px !important;
    min-height: 50vh;
  }
}
.back-btn {
  font-size: 24px;
}
.footer-text {
  font-size: 16px;
  color: #ffffff;
  font-weight: 500;
  margin-top: 20px;
}
