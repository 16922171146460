.table-header-view {
  border: 1px solid #f3f3f3 !important;
  flex: 1;
  display: flex;
  justify-content: center;
  border-radius: 4px;
}

.table-body-view {
  border: 1px solid #f3f3f3 !important;
  flex: 1;
  display: flex;
  justify-content: center;
  min-height: 144px;
  border-radius: 8px;
}

.empty-table-body {
  background-color: #f9f9fb !important;
  flex: 1;
  display: flex;
  justify-content: center;
  min-height: 144px;
}

.table-header-text {
  font-size: 16px;
  font-weight: 600;
  color: #969696;
}

.table-row {
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 5px !important;
}

.table {
  width: 100%;
}
.table-row {
  display: flex;
  justify-content: space-between;
}

.table-data-events {
  border-radius: 4px;
  width: fit-content;
  max-height: 80px;
  overflow-y: auto;
  overflow-x: hidden;
}
.table-data-events::-webkit-scrollbar {
  width: 8px;
}

.table-data-events::-webkit-scrollbar-track {
  background: #f6f6f6;
  border-radius: 4px;
}

.table-data-events::-webkit-scrollbar-thumb {
  background: #e5e5e5;
  border-radius: 4px;
}

.table-data-events::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.table-events-text {
  color: #424242;
  font-size: 14px;
  font-weight: 400;
  background-color: #f6f3ff;
  padding: 4px 8px !important;
}

.month-text {
  font-size: 24px;
  font-weight: 600;
  color: #585757;
}
