.edit-profile-screen-view {
  padding: 16px;
}

.edit-profile-text {
  color: #2e2a40;
  font-size: 16px;
  font-weight: 600;
}

.modal-heading {
  color: #2e2a40;
  font-size: 32px;
  text-align: center;
  font-weight: 600;
}

.modal-description {
  font-size: 16px;
  color: #4d4d4d;
  font-weight: 400;
  text-align: center;
}
