.account-activity-screen {
  padding: 16px;
  height: 91vh;
}

.table-view .tale-user-view {
  display: flex;
  @media (max-width: 576px) {
    flex-direction: column !important;
  }
}

.table-view .table-head-view {
  vertical-align: top;
}
.table-view .table-actions-view {
  display: flex;
  @media (max-width: 576px) {
    flex-direction: column !important;
  }
}
.table-view .pagination-view {
  @media (max-width: 576px) {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
.account-activity-text {
  color: #2e2a40;
  font-size: 16px;
  font-weight: 600;
}
.table-view {
  padding: 8px !important;
  background-color: #fff;
  padding: 16px;
  border-radius: 16px;
  overflow-x: auto;
}
.table-row {
  padding-bottom: 20px;
}

.table-title {
  color: #757575 !important;
  font-size: 12px !important;
  padding-bottom: 20px !important;
}

.image-view {
  height: 44px;
  width: 44px;
  border-radius: 50%;
}

.name-text {
  color: #2e2a40;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.activity-text {
  font-size: 14px;
  color: #757575;
  font-weight: 500;
  margin-top: 12px;
  margin-bottom: 0px;
}

.table-data {
  border-bottom: 1px solid #f3f3f3;
}

.pagination-text {
  color: #bababa;
  font-size: 12px;
  font-weight: 500;
}
