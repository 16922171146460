.pagination {
  gap: 16px !important;
}
.pagination .page-item span {
  border-radius: 4px !important;
}
.pagination .page-item a {
  border-radius: 4px !important;
}

.next-prev-text {
  font-size: 12px;
  color: #bababa;
}
