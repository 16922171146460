.agent-title {
  color: #1d1b20;
  font-size: 14px;
  font-weight: 600;
}

.agent-desc {
  color: #49454f;
  font-size: 14px;
  font-weight: 400;
}

.agent-card-view {
  display: flex;
  border-bottom: 1px solid #f3f3f3;
  margin: 0px 30px;
  @media (max-width: 576px), (max-width: 400px) {
    flex-direction: column !important;
    gap: 8px !important;
    margin: 0px 10px;
  }
}
