.agent-call-settings-form {
  height: 80vh;
  @media (max-width: 576px) {
    height: 100% !important;
  }
}

.save-btn-view {
  margin-top: 20px;
  padding-top: 32px;
  border-top: 2px solid #f3f3f3;
}
