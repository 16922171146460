.edit-agent-detail-form {
  padding: 32px;
}
.remove-btn-text {
  color: #d13e3e;
  font-size: 14px;
  font-weight: 400;
}
.remove-btn {
  background-color: #fff3f3 !important;
  padding: 8px 32px;
}
.save-btn {
  background-color: #2f80ed !important;
  padding: 8px 32px;
}

.next-btn {
  background-color: #fff !important;
  border: 1px solid #2f80ed;
  padding: 8px 32px;
}

.save-btn-text {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
}

.next-btn-text {
  color: #2f80ed;
  font-size: 14px;
  font-weight: 400;
}

.edit-agent-detail-form .agent-actions-button {
  display: flex;
  @media (max-width: 400px) {
    gap: 10px;
    flex-direction: column !important;
  }
}

.edit-agent-detail-form .agent-actions-button .combined-action-button {
  display: flex;
  @media (max-width: 400px) {
    gap: 10px;
    flex-direction: column !important;
  }
}
