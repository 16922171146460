.auth-info-container {
  position: relative;
  background-image: url('../../../assets/images/background.png');
  padding-left: 70px;
  padding-right: 70px;
  @media (min-width: 1400px) {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  @media (max-width: 1400px) {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  @media (max-width: 1200px) {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  @media (max-width: 992px) {
    padding-left: 0px !important;
    padding-right: 0px !important;
    min-height: 70vh;
  }
}

.carousel-container {
  display: flex;
  flex: 1;
  margin-bottom: 60px;
  width: 100%;
}

.info-container {
  background: linear-gradient(#006be0, #007aff);
  padding-top: 20px;
  padding-bottom: 50px;
  border-radius: 12px;
  min-height: 220px;
}

.header-text {
  font-size: 18px;
  font-weight: 400;
  color: #0d3057;
}

.title-text {
  font-weight: 700;
  font-size: 32px;
  color: #ffffff;
}

.description-carousel-text {
  font-size: 18px;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
}

.image-container {
  position: absolute;
  bottom: 10%;
  @media (max-width: 576px) {
    bottom: 15% !important;
  }
}

.image {
  @media (max-width: 768px) {
    width: 450px !important;
  }
  @media (max-width: 576px) {
    width: 350px !important;
  }
  @media (max-width: 400px) {
    width: 300px !important;
  }
}

.carousel-indicators button {
  height: 7px !important;
  width: 7px !important;
  border-radius: 50%;
  background-color: #125196 !important;
}

.carousel-indicators .active {
  height: 5px !important;
  width: 15px !important;
  margin: 0px !important;
  border-radius: 10px !important;
  padding: -5px !important;
  border-top: none !important;
  border-bottom: none !important;
  align-items: center !important;
  background-color: #95c6fb !important;
}

.carousel-indicators {
  align-items: center;
}
