.price-card {
  padding: 16px;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  box-shadow: 0px 4px 24px rgba(184, 184, 184, 0.12);
  border-radius: 20px;
  transition: 150ms;
  min-height: 550px;
  min-width: 350px;
  @media (max-width: 576px) {
    min-width: 300px;
  }
  &:hover {
    scale: 1.02;
  }
}
.price-card .price-card-title {
  font-weight: 700;
  font-size: 38px;
  color: #2d2d2d;
}
.price-card .desg-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #676562;
}

.price-card .price-disp-text .strike-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  text-decoration-line: line-through;
  color: #676562;
  opacity: 0.4;
  margin-bottom: 0px;
}
.price-card .price-disp-text .price-text {
  sub {
    font-size: 16px;
    color: #676562;
    vertical-align: 4px;
  }
  .price {
    font-size: 34px;
    line-height: 34px;
    margin-right: 1px;
  }
}

.price-card .price-disp-text .note-text {
  font-weight: 400;
  font-size: 10px;
  line-height: 26px;
  text-align: center;
  color: #ff0000;
}
.price-card .price-disp-text .inner-sec-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #676562;
}

.icon-view {
  width: 10%;
}

.text-view {
  width: 85%;
  @media (max-width: 768px) {
    white-space: normal;
    word-wrap: break-word;
  }
}

.plan-button {
  padding: 12px !important;
  border-radius: 12px !important;
}
