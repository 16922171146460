.add-agent-form-view {
  padding: 32px 16px 16px 16px;
}

.add-agent-form {
  background-color: #fff;
  padding: 16px;
  border-radius: 12px;
}
.custom-input-text-area {
  background-color: #e7e7e7 !important;
  border-radius: 8px;
  padding: 8px 12px;
}

.cancel-button {
  position: absolute;
  top: 35px;
  right: 10px;
}
.cancel-top {
  position: absolute;
  top: 15px;
  right: 10px;
}
.cancel-button-input {
  position: absolute;
  top: 10px;
  right: 10px;
}

.add-agent-form .add-agent-forms-view {
  display: flex;
  flex-direction: row;
  @media (max-width: 576px), (max-width: 400px) {
    flex-direction: column !important;
    gap: 8px !important;
    margin-bottom: 32px;
  }
}

.show-error {
  color: #dc3545;
  font-size: 14px;
  font-weight: 400;
}
