.pricing-screen {
  height: 90vh;
  @media (max-width: 992px) {
    height: 100%;
  }
}

.pricing-screen .pricing-card-view {
  display: flex;
  background-color: #fff;
  border-radius: 16px;
  padding: 16px;
  padding: 16px;
  overflow-x: auto;
  white-space: nowrap;
  @media (max-width: 992px) {
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }
  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 50px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #e5e5e5 !important;
    border-radius: 50px;
  }
  &::-webkit-scrollbar-track {
    background-color: #f6f6f6;
  }
}
