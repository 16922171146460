.verification-failed-btn {
  background-color: #fff3f3;
  padding: 16px;
  border-radius: 12px;
}

.verification-failed-text {
  color: #eb6565;
  font-size: 16px;
  font-weight: 600;
}
