.recharts-cartesian-grid-vertical {
  display: none !important;
}

.recharts-layer .recharts-cartesian-axis-tick line {
  display: none !important;
}
.recharts-default-legend {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.recharts-legend-wrapper {
  bottom: -35px !important;
}
.recharts-legend-item svg {
  border-radius: 50%;
  border-top: none !important;
  border-bottom: none !important;
  height: 10px !important;
  width: 10px !important;
}

.recharts-legend-item-text {
  color: #000 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.recharts-default-tooltip {
  background-color: #000 !important;
  border-radius: 8px !important;
}

.recharts-tooltip-label {
  color: #bababa !important;
  font-size: 12px !important;
}
.recharts-tooltip-item-list .recharts-tooltip-item {
  color: #fff !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}
.recharts-tooltip-item-name {
  display: none !important;
}

.recharts-tooltip-item-separator {
  display: none !important;
}
